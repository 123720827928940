<template>
    <div>
        <div v-if="preferenceProcessesShowStatus">
            <ValidationObserver ref="formModalValidate">
                <b-row class="mb-4">
                    <b-col cols="12" lg="9">
                        <div class="font-weight-medium mb-1">{{ $t('faculty').toUpper() + ' / ' + $t('program').toUpper() }}
                        </div>
                        <div class="mb-1">
                            <ValidationProvider name="faculty_code" rules="required" v-slot="{ valid, errors }">
                                <faculty-selectbox v-model="faculty_code" :validate-error="errors[0]" />
                            </ValidationProvider>
                        </div>
                        <div class="mb-2">
                            <ValidationProvider name="program_code" rules="required" v-slot="{ valid, errors }">
                                <multiselect :placeholder="$t('select')" v-model="program_code" :options="programOptions"
                                    :class="errors[0] ? 'is-invalid' : ''" label="text" track-by="value"
                                    :close-on-select="true" :clear-on-select="true" :select-label="''" :selected-label="''"
                                    :deselect-label="''">
                                    <span slot="noOptions">{{ $t('no_options') }}</span>
                                    <span slot="noResult">{{ $t('no_result') }}</span>
                                </multiselect>
                                <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]" />
                            </ValidationProvider>
                        </div>
                    </b-col>
                    <b-col cols="12" lg="2">
                        <div class="font-weight-medium mb-1">{{ $t('rank').toUpper() }}</div>
                        <div class="mb-2">
                            <ValidationProvider name="rank" rules="required" v-slot="{ valid, errors }">
                                <b-select :options="rankOptions" v-model="rank" :class="errors[0] ? 'is-invalid' : ''" />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </ValidationProvider>
                        </div>
                    </b-col>
                    <b-col cols="12" lg="1">
                        <b-button variant="primary" block class="mt-0 mt-lg-5" @click="storePreference">
                            <i class="ri-add-line top-plus-1 mr-1"></i> {{ $t('add') }}
                        </b-button>
                    </b-col>
                </b-row>
            </ValidationObserver>
        </div>
        <div class="font-weight-medium mb-1" v-if="preferenceProcessesShowStatus">{{ $t('your_preferences').toUpper() }}
        </div>
        <b-table :empty-filtered-text="$t('no_result')" :empty-text="$t('no_result')" bordered responsive
            :items="preferences" :fields="preferencesFields" show-empty
            class="mb-4 table-dropdown no-scrollbar border rounded">
            <template #cell(status)="row">
                <span class="badge badge-warning" v-if="row.item.status.includes('waiting')">
                    {{ row.item.status_text.toUpper() }}
                </span>
                <span class="badge badge-success" v-else-if="row.item.status == 'approved'">
                    {{ row.item.status_text.toUpper() }}
                </span>
                <div v-else-if="row.item.status == 'declined'">
                    <div class="badge badge-danger">
                        {{ row.item.status_text.toUpper() }}
                    </div>
                    <div class="mt-1" v-if="row.item.explanation">
                        {{ row.item.explanation }}
                    </div>
                </div>
            </template>
            <template #cell(faculty_program)="row">
                {{ row.item.faculty_name }} <br>{{ row.item.program_name }}
            </template>
            <template #cell(process)="row">
                <b-button @click="deletePreference(row.item.id)" size="sm" type="button" variant="danger">
                    {{ $t('delete').toUpper() }}
                </b-button>
            </template>
        </b-table>
        <div class="d-flex justify-content-end">
            <b-button variant="primary" v-if="preferenceProcessesShowStatus && preferences.length > 0"
                @click="savePreferences">
                {{ $t('approved_go').toUpper() }}
            </b-button>
        </div>
    </div>
</template>

<script>
// Component
import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox'
import ProgramSelectbox from '@/components/interactive-fields/ProgramSelectbox'
import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox'

// Services
import MinorApplicationService from '@/services/MinorApplicationService'
import MinorApplicationPreferenceService from '@/services/MinorApplicationPreferenceService'

// Other
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
    components: {
        FacultySelectbox,
        ProgramSelectbox,
        SemestersSelectbox,

        ValidationProvider,
        ValidationObserver
    },
    props: {
        applicationData: {
            type: Object
        }
    },
    computed: {
        rankOptions() {
            let options = [1, 2, 3];
            let selected = this.preferences.length > 0 ? this.preferences.map(item => item.rank) : [];

            return options.filter(x => !selected.includes(x));
        },
        preferenceProcessesShowStatus() {
            return this.minorApplication.status == 'waiting_preference' ? true : false
        },
        preferencesFields() {
            let fields = [
                {
                    key: 'rank',
                    label: this.$t('order_of_preference').toUpper(),
                    tdClass: 'text-center align-middle',
                    thClass: 'text-center',
                    sortable: false
                },
                {
                    key: 'faculty_program',
                    label: this.$t('faculty').toUpper() + ' / ' + this.$t('program').toUpper(),
                    tdClass: 'align-middle'
                },
                {
                    key: 'status',
                    label: this.$t('status').toUpper(),
                    tdClass: 'text-center align-middle',
                    thClass: 'text-center',
                    sortable: false
                }
            ];
            if (this.preferenceProcessesShowStatus) {
                fields.push({
                    key: 'process',
                    label: '',
                    tdClass: 'd-flex justify-content-center align-middle',
                    thClass: 'text-center',
                    sortable: false
                });
            }

            return fields;
        }
    },
    data() {
        return {
            formLoading: false,

            id: null,
            minorApplication: {},
            preferences: [],

            faculty_code: null,
            program_code: null,
            rank: 1,

            programOptions: []
        }
    },
    watch: {
        faculty_code: function (newValue, oldValue) {
            if (newValue != oldValue) {
                this.getProgram(newValue)
            }
        }
    },
    created() {
        this.id = this.applicationData.id;
        this.minorApplication = this.applicationData;
        this.preferences = this.applicationData.minor_application_preferences
    },
    methods: {
        async storePreference() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                this.formLoading = true;
                let formData = {
                    'minor_application_id': this.applicationData.id,
                    'faculty_code': this.faculty_code,
                    'program_code': this.program_code.value,
                    'rank': this.rank
                }
                MinorApplicationPreferenceService.store(formData)
                    .then(response => {
                        this.getMinorApplication(this.id);
                        this.faculty_code = null
                        this.program_code = null
                        this.rank = null
                    })
                    .then(() => {
                        this.$refs.formModalValidate.reset();
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false
                    })
            }
        },
        deletePreference(id) {
            this.$swal
                .fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                    icon: 'question'
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        MinorApplicationPreferenceService.del(id)
                            .then((response) => {
                                this.getMinorApplication(this.id);
                            })
                            .then(() => {
                                this.$refs.formModalValidate.reset();
                            })
                            .catch((e) => {
                                this.showErrors(e);
                            });
                    }
                });
        },
        savePreferences() {
            MinorApplicationPreferenceService.save()
                .then(response => {
                    let data = response.data.data;
                    this.$emit('savePreferences', this.id)
                })
                .catch(e => {
                    this.showErrors(e);
                })
        },

        getMinorApplication(id) {
            MinorApplicationService.get(id)
                .then(response => {
                    let data = response.data.data;
                    this.minorApplication = this.applicationData;
                    this.preferences = data.minor_application_preferences
                })
                .catch(e => {
                    this.showErrors(e);
                })
        },

        getProgram(facultyCode) {
            this.programOptions = [];
            if (facultyCode) {
                MinorApplicationService.programs(facultyCode)
                    .then(response => {
                        let data = response.data.data;
                        data.map((item) => {
                            this.programOptions.push({
                                value: item.program_code,
                                text: this.getLocaleText(item, 'program_name') + ' (' + this.$t('quota').toUpper() + ': ' + item.quota + ')'
                            })
                        })
                    })
                    .catch(e => {
                        this.showErrors(e);
                    })
            }
        }
    }
}
</script>
